<template>
  <section
    id="cuckoo-info"
  >
    <b-row>
      <b-col
        class="ml-2 mr-2"
      >
        <b-row>
          <b-col
            xs="12"
            md="7"
            class="mx-auto"
          >
            <b-card>
              <div>
                <h2
                  class="bold d-flex"
                >
                  <span class="mr-1">
                    Go live as early as
                    <span v-if="estInstallDate"> {{estInstallDate}} </span>
                  </span>
                  <span
                    v-if="loadingValidatedDate"
                    class="content-spinner"
                  >
                    <span class="d-flex align-items-center">
                      <b-spinner
                        small
                        type="grow"
                        variant="secondary"
                      />
                      <b-spinner
                        type="grow"
                        variant="dark"
                      />
                      <b-spinner
                        small
                        type="grow"
                        variant="secondary"
                      />
                    </span>
                  </span>
                </h2>
                <p>
                  Great news! You don't need an engineer. Just setup Cuckoo yourself on your go-live date.
                </p>
              </div>
              <div>
                <b-form-checkbox v-model="dateChecked"> I need a later date </b-form-checkbox>
                <div
                  v-if="dateChecked"
                >
                  <b-form-datepicker
                    id="example-i18n-picker"
                    locale="en-US"
                    class="mb-1 mt-1"
                    :date-disabled-fn="dateDisabled"
                    
                  />
                  <p>
                    You can choose your go-live date up to 2 years from today. If you're
                    still in a contract, you can still sign up to Cuckoo today but the
                    switching process will only begin 2 weeks before your actual switch date.
                  </p>

                  <div class="pb-1">
                    <b>Your account will be created 2 weeks before your go-live date.</b>
                  </div>
                  <div>
                    <b>
                      If you have selected our 1 month rolling contract, you will be charged the £60
                      set-up cost roughly 2 weeks before your actual switch date.
                    </b>
                  </div>
                </div>
                <div class="mt-1 d-flex">
                  <span>
                    <button color="#000000" disabled="" class="info-button">
                      <span>i</span>
                    </button>
                  </span>
                  <p>
                    We are required to wait at least 2 weeks before activating your connection
                    but it may take longer based on network availability at your property.
                  </p>
                </div>
              </div>
            </b-card>
            <div class="mt-4">
              <h2>Your details</h2>
              <b-form-group
                label="First name"
                label-for="first-name"
                class="mb-2"
              >
                <b-form-input
                  id="first-name"
                  placeholder="First name"
                  autofocus
                  autocomplete="off"
                />
              </b-form-group>

              <b-form-group
                label="Last Name"
                label-for="last-name"
                class="mb-2"
              >
                <b-form-input
                  id="last-name"
                  placeholder="Last name"
                  autofocus
                  autocomplete="off"
                />
              </b-form-group>

              <b-form-group
                label="Mobile number"
                label-for="mobile-number"
                class="mb-2"
              >
                <b-form-input
                  id="mobile-number"
                  placeholder="Mobile number"
                  autofocus
                  autocomplete="off"
                />
              </b-form-group>

              <b-form-group
                label="Email"
                label-for="email"
                class="mb-2"
              >
                <b-form-input
                  id="email"
                  placeholder="Email"
                  autofocus
                  autocomplete="off"
                />
              </b-form-group>

              <b-form-group
                label="Landline"
                label-for="landline"
                class="mb-2"
              >
                <b-form-input
                  id="landline"
                  placeholder="Enter your landline (if know)"
                  autofocus
                  autocomplete="off"
                />
              </b-form-group>
            </div>

            <div>
              <h2>Use your own router?</h2>
              <div
                v-on:click="handleNeedRouter"
                class="needRouter"
                :class="{
                  'activeNeedRouter': needRouter
                }"
              >
                I need a router
              </div>

                <div v-if="needRouter">
                  <h2 class="d-flex">
                    <span>
                      Router delivery est.
                      <span v-if="estRouterDeliveryDate"> {{estRouterDeliveryDate}} </span>
                    </span>

                    <span
                      v-if="loadingValidatedDate"
                      class="content-spinner"
                    >
                      <span class="d-flex align-items-center">
                        <b-spinner
                          small
                          type="grow"
                          variant="secondary"
                        />
                        <b-spinner
                          type="grow"
                          variant="dark"
                        />
                        <b-spinner
                          small
                          type="grow"
                          variant="secondary"
                        />
                      </span>
                    </span>
                  </h2>
                  <p>Our routers are free, we just ask that you send them back if you leave Cuckoo so that we can recycle them.</p>
                </div>

              <b-form-checkbox
                v-model="personalRouterChecked"
                v-on:change="handlePersonalRouterChecked"
                class="mt-1"
              >
                I want to keep using my personal router
              </b-form-checkbox>
              <div
                class="information mt-1"
                v-if="personalRouterChecked"
              >
                <div>
                  <b>
                    IMPORTANT: Routers from other broadband providers are unlikely to work with Cuckoo and they may ask you to return them once you’ve switched.
                  </b>
                </div>
                <p>
                  Ok, we won’t send you a new router. We support most VDSL2 compatible routers and here's our handy guide on how to connect them to Cuckoo.
                </p>
              </div>
              <b-form-checkbox
                v-model="routerDelivered"
                v-if="needRouter"
                class="mt-1"
              >
                I want the router delivered to a different address
              </b-form-checkbox>
              <div
                class="mt-1"
                v-if="routerDelivered"
              >
                <b-form-group
                  label="Building or door number"
                  label-for="door-nomber"
                  class="mb-2"
                >
                  <b-form-input
                    id="door-nomber"
                    placeholder="Building or door number"
                    autofocus
                    autocomplete="off"
                  />
                </b-form-group>

                <b-form-group
                  label="First line of address"
                  label-for="first-line-address"
                  class="mb-2"
                >
                  <b-form-input
                    id="first-line-address"
                    placeholder="First line of address"
                    autofocus
                    autocomplete="off"
                  />
                </b-form-group>

                <b-form-group
                  label="Second line of address (optional)"
                  label-for="second-line-address"
                  class="mb-2"
                >
                  <b-form-input
                    id="second-line-address"
                    placeholder="Second line of address (optional)"
                    autofocus
                    autocomplete="off"
                  />
                </b-form-group>

                <b-form-group
                  label="City"
                  label-for="city"
                  class="mb-2"
                >
                  <b-form-input
                    id="city"
                    placeholder="City"
                    autofocus
                    autocomplete="off"
                  />
                </b-form-group>

                <b-form-group
                  label="Postcode"
                  label-for="postcode"
                  class="mb-2"
                >
                  <b-form-input
                    id="postcode"
                    placeholder="Postcode"
                    autofocus
                    autocomplete="off"
                  />
                </b-form-group>



              </div>
              <b-form-checkbox
                v-model="personaliseRouter"
                v-if="needRouter"
                class="mt-1"
              >
                I want to personalise my router's Wi-Fi name and password
              </b-form-checkbox>
              <div
                v-if="personaliseRouter"
                class="mt-1"
              >
                <b-form-group
                  label="Wi-Fi name"
                  label-for="wi-fi-name"
                  class="mb-2"
                >
                  <b-form-input
                    id="wi-fi-name"
                    placeholder="Wi-Fi name"
                    autofocus
                    autocomplete="off"
                  />
                </b-form-group>
                <b-form-group
                  label="Wi-Fi password"
                  label-for="wi-fi-password"
                  class="mb-2"
                >
                  <b-form-input
                    id="wi-fi-password"
                    placeholder="Wi-Fi password"
                    autofocus
                    autocomplete="off"
                  />
                </b-form-group>
              </div>

              <div
                class="mt-1"
              >
                <p>
                  Ok, we won’t send you a new router. We support most
                  VDSL2 compatible routers and here's our handy guide on
                  how to connect them to Cuckoo.
                </p>

                <div></div>
              </div>
            </div>
          </b-col>

          <b-col
            xs="12"
            md="5"
            class="mx-auto"
          >
            <div
              class="information"
            >
              <div
                class="pricing-badge text-left margin-buttom"
              >
                <b-badge
                  variant="light-primary"
                  pill
                >
                  KEY INFO
                </b-badge>
              </div>

              <div>
                <h2>Your address</h2>
                <p class="pb-2" v-if="user">
                  <span v-if="user.moves">{{user.moves[0].moveDetails.moveToAddress}}</span>
                </p>
              </div>

              <div v-if="cuckoo.perMonth">
                <h2>{{cuckoo.perMonth}}</h2>
                <p class="pb-1">
                  We'll charge you if you make phone calls on your landline. Here's the full list of call charges.
                </p>
                <p class="pb-2">
                  There may be some circumstances where you'll need to pay more - for example,
                  if a new line has to be installed and you miss an engineer visit from Openreach
                  (you will be made aware if an engineer visit is needed). Here's a full list of other charges.
                </p>
              </div>

              <div v-if="cuckoo.cost">
                <h2>{{cuckoo.cost}}</h2>
                <p class="pb-2">
                  This will be deducted from your account within 2-5 working days via Direct Debit.
                  This covers connection costs with Openreach, regardless of whether you need a new
                  line, engineer or if we're taking over the existing line. You will need £60 in
                  your account or the switch will be cancelled. We'll also send you a router,
                  worth £82 RRP, in the post.
                </p>
              </div>

              <div v-if="cuckoo.price">
                <h2>{{cuckoo.price}}</h2>
                <p class="pb-1">
                  Whatever the next 12 months brings, a price rise for your broadband won't be one of them.
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BBadge, BFormCheckbox, BFormDatepicker, BFormInput, BFormGroup, BCard, BButton, BSpinner
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import axiosCustom from '@axios';

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BFormCheckbox,
    BFormDatepicker,
    BFormInput,
    BFormGroup,
    BCard,
    BButton,
    BSpinner,
  },
  data() {
    return {
      user: {},
      cuckoo: {},
      dateChecked: false,
      needRouter: false,
      personalRouterChecked: false,
      personaliseRouter: false,
      routerDelivered: false,

      loadingValidatedDate: false,
      estInstallDate: undefined,
      estRouterDeliveryDate: undefined,

      appointments: [],
    }
  },
  created() {
    this.$http.get('/cuckoo/info').then(res => { this.cuckoo = res.data })

    const userData = getUserData()
    this.user = userData

    this.loadingValidatedDate = true
    axiosCustom.post(`cuckoo/routerDelivery`, {
      "date": `${this.$dayjs().format("YYYY-MM-DD")}T00:00:00.000Z`
    })
    .then( ({
      data: {
        data
      }
    }) => {
      if(data.getTimeCalculations){
        this.estInstallDate = data.getTimeCalculations.estInstallDate
        this.estRouterDeliveryDate = data.getTimeCalculations.estRouterDeliveryDate
        return
      }
      this.$toast.warning("invalid date", {
        style: {
          backgroundColor: '#ecb90d',
          color: '#fff'
        }
      })
    })
    .catch((error) => {
      this.$toast.error("Error processing request", {
        style: {
          backgroundColor: '#ff5252',
          color: '#fff'
        }
      })
    })
    .finally(() => {
      this.loadingValidatedDate = false
    })

    axiosCustom.get(`cuckoo/getProducts?email=${userData.email}`)
    .then( ({data}) => {
      this.appointments = data.appointments.data.getAvailableEngineerAppointments
    })
    .catch((error) => {
      this.$toast.warning("No available date", {
        style: {
          backgroundColor: '#ecb90d',
          color: '#fff'
        }
      })
    })
    .finally(() => {
      this.loading = false
    })
  },
  methods: {
    handleNeedRouter: function() {
      this.needRouter = !this.needRouter
      if(this.needRouter){
        this.personalRouterChecked = false
      }
      else{
        this.personalRouterChecked = true
        this.personaliseRouter = false
        this.routerDelivered = false
      }
    },
    handlePersonalRouterChecked: function() {
      if(this.personalRouterChecked){
        this.needRouter = false
        this.personaliseRouter = false
        this.routerDelivered = false
      }
      else{
        this.needRouter = true
        this.personaliseRouter = false
        this.routerDelivered = false
      }
    },
    dateDisabled: function(ymd, date) {
      const weekday = date.getDay()
      const day = date.getDate()
      const month = new Date(date).getMonth()
      const isAvailable = this.appointments.some(appointment => Number(new Date(appointment.date)) === Number(new Date(date)) )
      return weekday === 0 || weekday === 6 || ( day < 30 && month < 3 ) || !isAvailable
    },
  }
}
</script>

<style lang="scss">
  .cuckoo-info{
    .information{
      background-color: rgb(255, 255, 255);
      padding: 1rem;
    }
    .info-button{
      border-radius: 50%;
      background-color: white;
      border: 1px solid rgb(0, 0, 0);
      color: rgb(0, 0, 0);
      font-size: 8px;
      font-weight: 700;
      cursor: default;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      padding: 0px;
      width: 15px;
      height: 15px;
    }
    .needRouter{
      display: flex;
      justify-content: center;
      padding: 2rem;
      margin-bottom: 10px;
      font-size: 16px;
      border: 1px solid #ccc;
    }
    .activeNeedRouter{
      border: 1px solid #7367f0;
    }
    .input{
      border-radius: 30px;
    }
    .bold{
      font-weight: 700;
      color: #000 !important;
    }
    p, b, div{
      color: #000 !important;
    }
    .cuckoo-button{
        border-radius: 100px;
        background-color: rgb(5, 128, 229) !important;
        color: rgb(255, 255, 255) !important;
        padding: 20px 25px;
        text-decoration: none;
        font-weight: 700;
        border: none;
      }
  }
</style>
